import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/privacy.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'Accommodation agreement',
            sub: '宿泊約款',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/site/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/site/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '規約・約款',
                path: '/privacy/',
              },
            ],
            current: {
              label: '宿泊約款',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect p_privacy">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">宿泊約款</h2>
          <h3 className="c_headingLv5">適用範囲</h3>
          <p className="u_mb25">
            第1条　当ホテルが宿泊客との間で締結する宿泊契約及びこれに関連する契約は、この約款の定めるところによるものとし、この約款に定めのない事項については、法令等(法令又は法令に基づくものをいう。以下同じ。)または一般に確立された慣習によるものとします。
            <br />
            2. 当ホテルが法令等及び慣習に反しない範囲で特約に応じたときは、前項の規定にかかわらず、その特約が優先するものとします。
          </p>
          <h3 className="c_headingLv5">宿泊契約の申込み</h3>
          <p className="u_mb25">
            第2条　当ホテルに宿泊契約の申込みをしようとする者は、次の事項を当ホテルに申し出ていただきます。
          </p>
          <p className="u_mb25">
            (1)宿泊者名
            <br />
            (2)宿泊日及び到着予定時刻
            <br />
            (3)宿泊料金（原則として別表第１の基本宿泊料による。）
            <br />
            (4)その他当ホテルが必要と認める事項
            <br />
            2. 宿泊客が、宿泊中に前項第２号の宿泊日を超えて宿泊の継続を申し入れた場合、当ホテルは、その申し出がなされた時点で新たな宿泊契約の申込みがあったものとして処理をするものとし、その時点で当ホテルが定める新たな前受金をお支払いいただきます。
          </p>
          <h3 className="c_headingLv5">宿泊契約の成立等</h3>
          <p className="u_mb25">
            第3条　宿泊契約は、当ホテルが前条の申込みを承諾したときに成立するものとします。ただし、当ホテルが承諾をしなかったことを証明したときは、この限りではありません。
          </p>
          <p className="u_mb25">
            2.
            前項の規定により宿泊契約が成立したときは、当ホテルが定める前受金を到着時または当ホテルが指定する期日までにお支払いいただきます。
            <br />
            3.
            滞在中の宿泊代金等の利用額が前受金の額を上回った場合、或いは上回ると見込まれる場合、当ホテルが指定するときまでに、当ホテルが定める金額の追加金を申し受けます。当ホテルが指定するときまでにご入金いただけない場合、第7条第1項第1号の規定にしたがい、当ホテルは宿泊契約を解除することがあります。
            <br />
            4.
            前受金及び追加金は、まず、宿泊客が最終的に支払うべき宿泊料金に充当し、第6条及び第17条の規定を適用する事態が生じたときは、違約金に次いで賠償金の順序で充当し、残額があれば、第12条の規定による料金の支払いの際に返還します。
            <br />
            5.
            第2項の前受金の支払いを当ホテルが事前に告知して求めたにもかかわらず、到着時または当ホテルが指定した日までにお支払いいただけない場合は、宿泊契約はその効力を失うものとします。
          </p>
          <h3 className="c_headingLv5">
            前受金の支払いを要しないこととする特約
          </h3>
          <p className="u_mb25">
            第4条　前条第2項の規定にかかわらず、当ホテルは、旅行小切手、宿泊券、クレジットカード等通貨に代わり得る方法の呈示を受け、有効性確認ができ、その他必要な手続を経た場合、契約の成立後同項の前受金の支払いを要しないこととする特約に応じることがあります。
          </p>
          <p className="u_mb25">
            2.
            宿泊契約の申込みを承諾するに当たり、当ホテルが前条第2項の前受金の支払いを求めなかった場合及び当該前受金の支払期日を指定しなかった場合は、前項の特約に応じたものとして取り扱います。
          </p>
          <p className="u_mb25">
            3.
            施設における感染症防止対策への協力の求め　当ホテルは、宿泊しようとする者に対し、旅館業法(昭和23年法律第138号)第4条の2第1項の規定による協力を求めることができます。
          </p>
          <h3 className="c_headingLv5">宿泊契約締結の拒否</h3>
          <p className="u_mb25">
            第5条　当ホテルは、次に掲げる場合において、宿泊契約の締結に応じないことがあります。 ただし、本項は、当ホテルが、旅館業法第5条に掲げる場合以外の場合に宿泊を拒むことがあることを意味するものではありません。
            <br />
            (1)宿泊の申込みが、この約款によらないとき。
            <br />
            (2)満室により客室の余裕がないとき。
            <br />
            (3)宿泊しようとする者が、宿泊に関し、宿泊約款（＝本約款）、法令の規定、公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき。
            <br />
            (4)宿泊に関し、暴力的要求行為が行われ、又は合理的な範囲を超える負担を求められたとき。(宿泊しようとする者が障害を理由とする差別の解消の推進に関する法律(平成25年法律第65号。以下「障害者差別解消法」という。)第7条第2項又は第8条第2項の規定による社会的障壁の除去を求める場合は除く。)
            <br />
            (5)宿泊しようとする者が、当ホテルに対し、その実施に伴う負担が過重であって他の宿泊者に対する宿泊に関するサービスの提供を著しく阻害するおそれのある要求として旅館業法施行規則第5条の6で定めるものを繰り返したとき。
            <br />
            (6)天災、施設の故障、その他やむを得ない事由により宿泊させることができないとき。
            <br />
            (7)宿泊しようとする者がでい酔し、または言動が著しく異常で、他の宿泊者らに迷惑を及ぼすおそれがあると認められるとき。（神奈川県旅館業法施行条例第4条）
            <br />
            (8)宿泊しようとする者が、宿泊申込みの際の申出事項を偽ったとき。
            <br />
            (9)宿泊しようとする者が、著しく不潔な身体、または服装をしているため、他の宿泊者に迷惑を及ぼすおそれがあると認められるとき。（神奈川県旅館業法施行条例第4条）
            <br />
            (10)その他当ホテルが宿泊させることを不当と認めたとき。
            <br />
            2. 当ホテルは、次に掲げる場合において、宿泊契約を締結いたしません。
            <br />
            (1)宿泊しようとする者が、暴力団員、または暴力団等の暴力関係団体その他反社会的勢力の関係者であるとき。
            <br />
            (2)宿泊しようとする者が、暴力団または暴力団員が事業活動を支配する法人その他の団体の関係者であるとき。
            <br />
            (3)宿泊しようとする者が、旅館業法第4条の2第1項第2号に規定する特定感染症の患者等(以下「特定感染症の患者等」という。)であるとき。
            <br />
            3. 宿泊契約締結の拒否の説明
            <br />
            宿泊しようとする者は、当ホテルに対し、当ホテルが前条に基づいて宿泊契約の締結に応じない場合、その理由の説明を求めることができます。
          </p>
          <h3 className="c_headingLv5">宿泊客の契約解除権</h3>
          <p className="u_mb25">
            第6条　宿泊客は、当ホテルに申し出て、宿泊契約を解除することができます。
          </p>
          <p className="u_mb25">
            2. 当ホテルは、宿泊客がその責めに帰すべき事由により宿泊契約の全部または一部を解除した場合（第3条第2項の規定により当ホテルが前受金の支払期日を指定してその支払いを求めた場合であって、その支払いより前に宿泊客が宿泊契約を解除したときを除きます。）は、別表第2に掲げるところにより、違約金を申し受けます。ただし、当ホテルが第4条第1項の特約に応じた場合にあっては、その特約に応じるにあたって、宿泊客が宿泊契約を解除したときの違約金支払義務について、当ホテルが宿泊客に告知したときに限ります。
            <br />
            3. 当ホテルは、宿泊客が連絡をしないで宿泊日当日の午後8時（あらかじめ到着予定時刻が明示されている場合は、その時刻を2時間経過した時刻）になっても到着しないときは、その宿泊契約は宿泊者により解除されたものとみなし処理することがあります。
          </p>
          <h3 className="c_headingLv5">当ホテルの契約解除権</h3>
          <p className="u_mb25">
            第7条　当ホテルは、次に掲げる場合においては、宿泊契約を解除することがあります。 ただし、本項は、当ホテルが、旅館業法第5条に掲げる場合以外の場合に宿泊を拒むことがあることを意味するものではありません。
          </p>
          <p className="u_mb25">
          (1)宿泊客が宿泊に関し、宿泊約款（＝本約款）、法令の規定、公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき、または同行為をしたと認められるとき。
            <br />
            (2)宿泊に関し、暴力的要求行為が行われ、又は合理的な範囲を超える負担を求められたとき。(宿泊客が障害者差別解消法第7条第2項又は第8条第2項の規定による社会的障壁の除去を求める場合は除く。)
            <br />
            (3)宿泊客が、当ホテルに対し、その実施に伴う負担が過重であって他の宿泊者に対する宿泊に関するサービスの提供を著しく阻害するおそれのある要求として旅館業法施行規則第5条の6で定めるものを繰り返したとき。
            <br />
            (4)天災等不可抗力に起因する事由により宿泊させることができないとき。
            <br />
            (5)宿泊客がでい酔し、または言動が著しく異常で、他の宿泊者らに迷惑を及ぼすおそれがあると認められるとき。（神奈川県旅館業法施行条例第4条）
            <br />
            (6)宿泊客が、宿泊申込みの際の申出事項または宿泊登録の際の登録事項を偽ったとき。
            <br />
            (7)宿泊しようとする者が、著しく不潔な身体、または服装をしているため、他の宿泊者に迷惑を及ぼすおそれがあると認められるとき。（神奈川県旅館業法施行条例第4条）
            <br />
            (8)その他当ホテルが定める利用規則の禁止事項に従わないとき。
            <br />
            2. 当ホテルは、次に掲げる場合においては、宿泊契約を解除いたします。
          </p>
          <p className="u_mb25">
            (1)宿泊客が、暴力団員、暴力団等、暴力関係団体その他反社会的勢力の関係者であるとき。
            <br />
            (2)宿泊客が、暴力団または暴力団員が事業活動を支配する法人その他の団体の関係者であるとき。
            <br />
            (3)宿泊客が、特定感染症の患者等であるとき。
            <br />
            3. 当ホテルが1項又は2項の規定に基づいて宿泊契約を解除したときは、宿泊客がいまだ提供を受けていない宿泊サービス等の料金はいただきません。
            <br />
            4. 宿泊契約解除の説明
          </p>
          <p className="u_mb25">
            宿泊客は、当ホテル(館)に対し、当ホテル(館)が前条に基づいて宿泊契約を解除した場合、その理由の説明を求めることができます。
          </p>
          <h3 className="c_headingLv5">宿泊の登録</h3>
          <p className="u_mb25">
            第8条　宿泊客は、宿泊日当日、当ホテルのフロントにおいて、次の事項を登録していただきます。
          </p>
          <p className="u_mb25">
          (1)宿泊客の氏名、住所及び職業
            <br />
            (2) 日本国内に住所を有しない外国人にあっては、国籍、旅券番号
            <br />
            (3)その他当ホテルが必要と認める事項
            <br />
            2. 宿泊客が第12条の料金の支払いを、旅行小切手、宿泊券、クレジットカード等通貨に代わり得る方法により行おうとするときは、前項登録時に当ホテルへ呈示を行い、有効性確認及びその他必要な手続を経ておく必要があります。
          </p>
          <h3 className="c_headingLv5">客室の使用時間</h3>
          <p className="u_mb25">
            第9条　宿泊客が当ホテルの客室を使用できる時間は、午後3時から翌日の午前11時までとします。ただし、連続して宿泊する場合においては、到着日及び出発日を除き、終日使用することができます。
            <br />
            2.
            当ホテルは、前項の規定にかかわらず、同項に定める時間外の客室の使用に応じることがあります。この場合には次に掲げる追加料金を申し受けます。
          </p>
          <p className="u_mb25">
            (1)超過3時間までは、室料金の30％
            <br />
            (2)超過6時間までは、室料金の50％
            <br />
            (3)超過6時間以上は、室料金の全額
          </p>
          <h3 className="c_headingLv5">利用規則の遵守</h3>
          <p className="u_mb25">
            第10条　宿泊客は、当ホテル内においては、当ホテルが定めてホテル内に提示した利用規則に従っていただきます。
          </p>
          <h3 className="c_headingLv5">営業時間</h3>
          <p className="u_mb25">
            第11条　施設等の詳しい営業時間は備付パンフレット、各所の掲示、客室内のサービスディレクトリー等でご案内いたします。
          </p>
          <p className="u_mb25">
            フロント　24時間
            <br />
            ベルキャプテン　24時間
            <br />
            飲食施設等のご案内　　詳しい営業時間は客室内インフォメーション、各所の表示でご案内致します。
            <br />
            スカイラウンジ「シリウス」（70階）
            <br />
            日本料理「四季亭」（68階）
            <br />
            フレンチレストラン「ル シエール」（68階）
            <br />
            中国料理「皇苑」（68階）
            <br />
            メインバー「ロイヤル アスコット」（2階）
            <br />
            デリカ＆ラウンジ「コフレ」（1階）
            <br />
            鉄板焼「よこはま」（地下1階）
            <br />
            レストラン＆バンケット「フローラ」（地下1階）
            <br />
            ルームサービス
            <br />
            2.
            前項の内容は、必要やむを得ない場合には臨時に変更することがあります。その場合には適当な方法をもってお知らせします。
          </p>
          <h3 className="c_headingLv5">料金の支払い</h3>
          <p className="u_mb25">
            第12条　宿泊客が支払うべき宿泊料金等の内訳及びその算定方法は、別表第1に掲げるところによります。
          </p>
          <p className="u_mb25">
            2.
            前項の宿泊料金の支払いは、当ホテル指定の通貨または当ホテルが認めた旅行小切手、宿泊券、クレジットカード等通貨に代わり得る方法により、宿泊約款第4条第1項の特約ある場合を除き宿泊客の到着時、または当ホテルが請求したとき、フロントにおいて行っていただきます。
            <br />
            3.
            当ホテルが宿泊客に客室を提供し、使用が可能になったのち、宿泊客が任意に宿泊しなかった場合においても、宿泊料金は申し受けます。
          </p>
          <h3 className="c_headingLv5">当ホテルの責任</h3>
          <p className="u_mb25">
            第13条　当ホテルは、宿泊契約及びこれに関連する契約の履行に当たり、またはそれらの不履行により宿泊客に損害を与えたときは、その損害を賠償します。ただし、それが当ホテルの責めに帰すべき事由によるものでないときは、この限りではありません。
          </p>
          <p className="u_mb25">
            2.
            当ホテルは、万一の火災等に対処するため、旅館賠償責任保険に加入しております。
          </p>
          <h3 className="c_headingLv5">
            契約した客室の提供ができないときの取扱い
          </h3>
          <p className="u_mb25">
            第14条　当ホテルは、宿泊客に契約した客室を提供できないときには、宿泊客の了解を得て、できる限り同一の条件による他の宿泊施設を斡旋するものとします。
          </p>
          <p className="u_mb25">
            2.
            当ホテルは、前項の規定にかかわらず他の宿泊施設の斡旋ができないときは、違約金相当額の補償料を宿泊客に支払い、その補償料は損害賠償額に充当します。ただし、客室が提供できないことについて、当ホテルの責めに帰すべき事由がないときは、補償料を支払いません。
          </p>
          <h3 className="c_headingLv5">寄託物等の取扱い</h3>
          <p className="u_mb25">
            第15条　宿泊客がフロントにお預けになった物品について、滅失、毀損等の損害が生じたときは、それが、不可抗力である場合を除き、当ホテルは、その損害を賠償します。ただし、お預けになった物品が現金または貴重品である場合、宿泊客がその種類及び価額の明告を行わなかったときは、当ホテルは一切その損害を賠償いたしません。
          </p>
          <p className="u_mb25">
            2.
            宿泊客が、当ホテル内にお持込みになった物品であってフロントにお預けにならなかったものについては、当ホテルの故意または過失により滅失、毀損等の損害が生じた場合以外は、当ホテルは、賠償いたしかねます。当ホテルが賠償する場合であっても、宿泊客からあらかじめ種類及び価額の明告のなかったものについては、15万円を限度として当ホテルはその損害を賠償します。なお、フロントにお預けにならなかった現金及び貴重品については、当ホテルは一切その損害を賠償いたしません。
          </p>
          <h3 className="c_headingLv5">宿泊客の手荷物または携帯品の保管</h3>
          <p className="u_mb25">
            第16条　宿泊客の手荷物が、宿泊に先立って当ホテルに到着した場合は、その到着前に当ホテルが了解したときに限って責任を持って保管し、宿泊客がフロントにおいてチェックインする際お渡しします。
          </p>
          <p className="u_mb25">
            2.
            宿泊客がチェックアウトしたのち、宿泊客の手荷物または携帯品が当ホテルに置き忘れられていた場合、法令に基いて当ホテルが相当と考える措置をとることとします。当該手荷物または携帯品の所有者が明確に判明したときは、当ホテルは、その裁量に基づき、当該所有者に連絡をするとともにその指示を求めることができる（ただし、義務ではない）ものとします。
            <br />
            3.
            前2項の場合における宿泊客の手荷物または携帯品の保管についての当ホテルの責任は、第1項の場合にあっては前条第1項の規定に、前項の場合にあっては同条第2項の規定に準じるものとします。
          </p>
          <h3 className="c_headingLv5">宿泊客の責任</h3>
          <p className="u_mb25">
            第17条　宿泊客の故意又は過失により当ホテルが損害を被ったときは、当該宿泊客は当ホテルに対し、その損害を賠償していただきます。
          </p>
          <h3 className="c_headingLv5">免責事項</h3>
          <p className="u_mb25">
            第18条　当ホテル内からのパソコン、携帯電話等を利用したインターネット、メールなどの通信のご利用にあたりましては、お客様ご自身の責任において行うものといたします。当該通信のご利用中にシステム障害、電波障害、停電その他の理由により、その結果利用者がいかなる損害を受けた場合においても、当ホテルは一切の責任を負いません。また、当該通信のご利用に際して当ホテルが不適切と事前または事後に判断した行為により、当ホテルまたは第三者に損害が生じた場合、その損害を賠償していただきます。
          </p>
          <h3 className="c_headingLv5">言語</h3>
          <p className="u_mb25">
            第19条　本約款は日本語を原文とし、英語は訳文とします。英語の訳文は日本語の原文の参考として作成されるものに過ぎず、すべて日本文によるものが優先することとします。
          </p>
          <h3 className="c_headingLv5">裁判管轄および準拠法</h3>
          <p className="u_mb25">
            第20条　本約款による宿泊契約等に関して生じる一切の紛争については、当ホテルの所在地を管轄する裁判所のうち、訴額に応じて簡易裁判所または地方裁判所を第一審の排他的合意管轄裁判所とします。
          </p>
          <p className="u_mb25">
            2. 本約款は日本法に準拠し、日本法に基づき解釈されることとします。
          </p>
          <p>別表第1　宿泊料金の内訳（第2条第1項及び第12条第1項関係）</p>
          <table className="c_fixedTable c_fixedTable--stay">
            <tbody>
              <tr>
                <th rowSpan={4} className="thTitle">
                  宿泊客が支払うべき
                  <br />
                  総額
                </th>
                <td colSpan={2}>内訳</td>
              </tr>
              <tr>
                <td className="tdWidth">宿泊料金</td>
                <td>
                  (1)基本宿泊料
                  <br />
                  (2)サービス料（(1)×15％）
                </td>
              </tr>
              <tr>
                <td>追加料金</td>
                <td>
                  (3)飲食及びその他の利用料金
                  <br />
                  (4)サービス料（(3)×15％）
                </td>
              </tr>
              <tr>
                <td>税金</td>
                <td>(イ)消費税（地方消費税を含む）</td>
              </tr>
            </tbody>
          </table>
          <p className="u_mb25">
            （備考）
            <br />
            1. 基本宿泊料は当ホテルが掲示する料金表によります。
            <br />
            2. 税法が改正された場合はその改正された規定によります。
          </p>
          <p>別表第2　違約金（第6条第2項関係）</p>
          {!isSp ? (
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th colSpan={2}>契約申込人数／契約解除の通知をうけた日</th>
                  <th>不泊</th>
                  <th>当日</th>
                  <th>前日</th>
                  <th>7～2日前</th>
                  <th>21～8日前</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>一般</td>
                  <td>9名まで</td>
                  <td>100％</td>
                  <td>100％</td>
                  <td>80％</td>
                  <td>40％</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>団体</td>
                  <td>10名以上</td>
                  <td>100％</td>
                  <td>100％</td>
                  <td>80％</td>
                  <td>40％</td>
                  <td>20％</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className="c_fixedTable">
              <tbody>
                <tr>
                  <th style={{ width: '135px' }} className="u_tal" rowSpan={2}>
                    契約申込人数／契約解除の通知をうけた日
                  </th>
                  <td>一般</td>
                  <td>団体</td>
                </tr>
                <tr>
                  <td>9名まで</td>
                  <td>10名以上</td>
                </tr>
                <tr>
                  <td>不泊</td>
                  <td>100％</td>
                  <td>100％</td>
                </tr>
                <tr>
                  <td>当日</td>
                  <td>100％</td>
                  <td>100％</td>
                </tr>
                <tr>
                  <td>前日</td>
                  <td>80％</td>
                  <td>80％</td>
                </tr>
                <tr>
                  <td>7～2日前</td>
                  <td>40％</td>
                  <td>40％</td>
                </tr>
                <tr>
                  <td>21～8日前</td>
                  <td>-</td>
                  <td>20％</td>
                </tr>
              </tbody>
            </table>
          )}

          <p className="u_mb25">
            （注）
            <br />
            1. ％は、基本宿泊料に対する違約金の比率です。
            <br />
            2.
            契約日数が短縮した場合は、その短縮日数にかかわりなく、1日分（初日）の違約金を収受します。
            <br />
            3.
            団体客（10名以上）の一部について契約の解除があった場合、宿泊の10日前（その日より後に申込をお引き受けした場合にはそのお引き受けした日）における宿泊人数の10％（端数が出た場合には切り上げる。）にあたる人数については、違約金はいただきません。
          </p>
          <h2 className="c_headingLv2">ご利用規則</h2>
          <h3 className="c_headingLv5">利用規則</h3>
          <p className="u_mb25">
            当ホテルではすべてのお客様に、安全かつ快適にお過ごしいただきますように、宿泊約款第10条の定めにある通り、下記の規則をお守りくださいますようお願いいたします。この規則をお守りいただけないときは、宿泊約款第7条により、ご宿泊契約及びこれに関連する契約を解除させていただく場合もございます。
          </p>
          <h3 className="c_headingLv5">火災予防上お守りいただきたい事項</h3>
          <p className="u_mb25">
            1.ベッドの中など火災の発生しやすい場所及び喫煙場所以外での喫煙はお断りいたします。
            <br />
            2. 照明器具の上に、タオルや衣類などをかけないでください。
            <br />
            3. 消防用設備等には、非常の場合以外はお手を触れないでください。
            <br />
            4.
            ホテル内に暖房用、炊事用等の火気及び火薬、揮発油その他発火、引火性のものはお持ち込みにならないでください。
            <br />
            5.
            客室よりの避難経路図は、客室入口ドアの裏側に掲示してありますのでご確認ください。
          </p>
          <h3 className="c_headingLv5">保安上お守りいただきたい事項</h3>
          <p className="u_mb25">
            1. ホテルに次のようなものをお持ち込みにならないでください。
            <br />
            a. 犬、猫、小鳥その他の愛玩動物
            <br />
            b. 悪臭、異臭を発生する物
            <br />
            c. 発火または引火しやすい火薬や揮発油等危険性のあるもの
            <br />
            d. その他法令で所持を禁じられている物
          </p>
          <p className="u_mb25">
            2.
            客室から出られるときは施錠をご確認ください。ご在室中や特にご就寝の時は内鍵とドアアームをおかけください。
          </p>
          <p className="u_mb25">
            3.
            ご来客があった場合には不用意に開扉なさらずドアスコープで確認されるか、ドアアームを掛けたままドアを半開きにしてご確認ください。
            <br />
            （不審者と思われる場合は、アシスタントマネージャー、または、フロントに至急ご連絡ください。）
          </p>
          <h3 className="c_headingLv5">おやめいただきたい行為</h3>
          <p className="u_mb25">
            1.
            ホテル内で賭博、または風紀を乱すような行為はなさらないでください。
            <br />
            2.
            ホテル内で他のお客様にご迷惑を及ぼすような大声、放歌、または、喧騒な行為はなさらないでください。
            <br />
            3.
            客室を当ホテルの許可なしに宿泊及び飲食以外の目的にご使用にならないでください。
            <br />
            4.
            ホテル内に飲食物をお持ち込みになったり、外部から出前等をおとりになることはおやめください。
            <br />
            5.
            ホテル内の諸設備、諸物品を当ホテルの許可なく他の場所へ移動させる等、現状を変更するようなことはなさらないでください。
            <br />
            6. ホテルの外観を損なう様なものを窓側に陳列なさらないでください。
            <br />
            7.
            宿泊登録者以外のご宿泊は固くお断わりいたします。なお、客室に外来のお客様をお招きにならないでください。
            <br />
            8.
            ホテル内では当ホテルの許可なしに、広告宣伝物の配布、掲示、または、物品の販売勧誘等はなさらないでください。
            <br />
            9.
            ホテル内で撮影された写真等を許可なく営業上の目的で公になさることは、法的措置の対象となることがありますのでご注意ください。
            <br />
            10.
            ゆかた、パジャマ、スリッパなどで廊下等室外にお出にならないでください。
          </p>
          <h3 className="c_headingLv5">お支払いについて</h3>
          <p className="u_mb25">
            1.
            ホテル内のレストラン、バー等をご署名によってご利用される場合は、客室の鍵、または、宿泊カードをご提示ください。但し、当ホテルが宿泊者等に対し、ご署名によるご利用のお断りを予め告知している場合や当ホテルの判断によりご利用いただけない場合もございます。
            <br />
            2.
            お買物代、切符代、タクシー代等のお立替はお断わりさせていただきます。
            <br />
            3.
            宿泊約款第4条第1項の特約ある場合を除き、到着時または当ホテルが指定する期日までに、当ホテルが定める前受金をお支払いいただきます。
            <br />
            4.
            ご滞在中でもフロントからの請求書の提示がございましたら、その都度お支払いください。
            <br />
            5.
            料金のお支払いは、当ホテル指定の通貨または当ホテルが認めた旅行小切手、クーポン券、若しくはクレジットカードによりフロントにてお支払いください。なお、旅行小切手以外の小切手でのお支払い及び両替には応じかねますのでご了承ください。
            <br />
            6.
            勝手ながら所定の税金の他お勘定の15％をサービス料として加算させていただきます。なお、従業員への心づけはご辞退申し上げます。
          </p>
          <h3 className="c_headingLv5">貴重品、お預かり品について</h3>
          <p className="u_mb25">
            1.
            現金、貴金属等の貴重品は客室内備付けの金庫に保管されるか、フロントの貸金庫（無料）にお預けください。
            <br />
            2.
            お引き取り忘れのお預かり物やお忘れ物等は、法令・約款に基づいて当ホテルが相当と考える措置をとることとします。
          </p>
          <h3 className="c_headingLv5">その他</h3>
          <p className="u_mb25">
            1.
            未成年者のみのご宿泊は、特に保護者の許可がない限りお断りいたします。
            <br />
            2.
            不可抗力以外の事由により建造物、家具、備品その他の物品を汚損、紛失された場合には、相当額を弁償していただくことがあります。
            <br />
            3.
            お部屋の鍵を紛失等によりご返却のない場合は、鍵代金の実費をお支払いただきます。
            <br />
            4.
            客室内よりお電話をご利用の際は施設使用料が加算されますのでご了承ください。
          </p>
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
